import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sections from "./../components/sections/Sections";
import Popup from "../components/Popup/Popup";

const IndexPage = ( props ) => (
  <Layout>
    {/* <Popup /> */}
    <SEO title="Wina" />
    <Sections
        data={ props.data.wordpressPage.acf.sections_page }
        pageId={ props.data.wordpressPage.id }
      />
  </Layout>
)
export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: { eq: 5 }) {
      id
      slug
      title
      wordpress_id
      content
      acf {
        sections_page {
          __typename
          ... on WordPressAcf_slider_text_section {
            id
          }
          ... on WordPressAcf_contact_section {
            id
          }
          ... on WordPressAcf_image_header_editor_section {
            id
          }
          ... on WordPressAcf_bookmark_section {
            id
          }
          ... on WordPressAcf_products_section {
            id
          }
          ... on WordPressAcf_map_section {
            id
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    wordpressSiteMetadata {
      home
    }
  }
`;

export default IndexPage
